<template>
  <div>
    <div class="modal-extrato-credito-titulo">
      <div class="modal-extrato-credito-titulo-titulo">Extrato de Crédito - {{ item.jsonData.fullName }}</div>
      <div class="modal-extrato-credito-titulo-total">Valor total: {{ totalValue | currency}}</div>    
    </div>
    <v-container>
      <div>
        <v-skeleton-loader
        :loading="loadingTable"
        :transition="'fade-transition'"
        height="150px"
        type="table">
          <v-data-table
          :headers="headers"
          :items="listExtratoCredito"
          flat>
            <template v-slot:item.value="{ item }">
              <span :style="`color: ${getColor(item.entryType.type)};`">
                {{ item.value | currency }}
              </span>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapGetters } from 'vuex'

export default {
  name: 'UsuarioWebExtratoCredito',
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'Descrição', value: 'description' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' }
    ],
  }),
  computed: {
    ...mapGetters('usuariosWeb', {listExtratoCredito: 'listExtrataTitulosCapitalizacao', item: 'item', loadingTable: 'loadingTable'}),
    variables: () => variables,
    totalValue () {
      let self = this
      let total = 0
      self.listExtratoCredito.forEach(element => {
        if (element.entryType.type === 'C') {
          total += parseFloat(element.value)
        } else {
          total -= parseFloat(element.value)
        }
      });
      return total
    }
  },

  methods: {
    getColor (type) {
      switch (type) {
        case 'D':
          return this.variables.colorError
        case 'C':
          return this.variables.colorSecondary
      }
    }
  }
}
</script>